import React, { useState, useEffect } from "react";
import PopularPostsMenu from "../../components/PopularPostsMenu";
import ShareButtons from "../../components/ShareButtons";
import { GoDotFill } from "react-icons/go";

import { BlogPost } from "../../types/types";
import { formattedDate } from "../../assets/utils";

interface PostProps {
  post: BlogPost;
  pathname: string;
}

const Post: React.FC<PostProps> = ({ post, pathname }) => {
  const [mostViewedPosts, setMostViewedPosts] = useState<BlogPost[] | []>([]);

  const { title, author, reading_time, date, image_src, html } = post;

  useEffect(() => {
    (async () => {
      const response = await fetch(`/redis/api/most-viewed-posts`);
      const result = await response.json();
      setMostViewedPosts(result);
    })();
  }, []);

  return (
    <div className="bg-gray-100 flex justify-center py-5">
      <main className="flex w-full flex-col lg:w-3/4">
        <p className="text-gray-700 mb-2 text-sm font-semibold">
          Posted on {formattedDate(date)}
        </p>
        <h1 className="fonr-light text-2xl md:text-3xl lg:text-5xl lg:font-semibold">
          {title}
        </h1>
        <div className="my-4 flex">
          <div className="text-gray-700 text-sm font-extralight">
            <span className="mr-1 text-xs">by</span>
            <a
              href={`/author/${author}`}
              className="font-semibold underline hover:text-blue-400"
            >
              {author}
            </a>
          </div>
          <div className="text-gray-700 ml-2 flex items-center text-sm font-extralight">
            <GoDotFill className="mr-1 text-[8px]" /> {reading_time} Min Read
          </div>
        </div>

        <ShareButtons pathname={pathname} />

        <div className="relative mb-3 w-full pb-[56.25%]">
          <img
            className="absolute left-0 top-0 h-full w-full object-cover"
            src={image_src}
            alt="What is the Average Attention Span"
          />
        </div>

        <article
          dangerouslySetInnerHTML={{ __html: html }}
          className="mb-10 w-full"
        />
      </main>
      <PopularPostsMenu posts={mostViewedPosts} />
    </div>
  );
};

export default Post;

import React, { RefObject } from "react";
import { CiTrash } from "react-icons/ci";
import { Tab } from "@/types/types";

interface ClearButtonProps {
  textareaRef: RefObject<HTMLTextAreaElement>;
  device: "desktop" | "mobile";
  setTabs: any;
  activeTabId: number;
}

const ClearButton: React.FC<ClearButtonProps> = ({
  textareaRef,
  device,
  setTabs,
  activeTabId,
}) => {
  const clearText = () => {
    if (textareaRef.current) {
      textareaRef.current.value = "";
      setTabs((prevTabs: Tab[]) => {
        return prevTabs.map((tab: { id: number; text: string }) => {
          return tab.id === activeTabId ? { ...tab, text: "" } : tab;
        });
      });
    }
  };

  return <CiTrash onClick={clearText} className={`clearText-${device}`} />;
};

export default ClearButton;

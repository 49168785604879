// import './index.css';
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App'
// import { TextProvider } from '@/assets/hooks/TextProvider.tsx';
// import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer } from 'react-toastify';

// const data = window.__INITIAL_DATA__

// ReactDOM.hydrateRoot(
//   document.getElementById('root') as HTMLElement,
//   // <React.StrictMode>
//     <BrowserRouter>
//       {/* <TextProvider> */}
//         <ToastContainer />
//         <App pathname={location.pathname} data={data} ssrManifest={undefined}/>
//       {/* </TextProvider>, */}
//     </BrowserRouter>
//   // </React.StrictMode>
// );

import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { TextProvider } from "./assets/hooks/TextProvider";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const data = window.__INITIAL_DATA__;

ReactDOM.hydrateRoot(
  document.getElementById("root") as HTMLElement,
  <React.StrictMode>
    <BrowserRouter>
      <TextProvider>
        <ToastContainer />
        <App pathname={window.location.pathname} data={data} />
      </TextProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

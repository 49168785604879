import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import About from "./pages/About";
import Post from "./pages/Post/Post";
import Blog from "./pages/Blog";
import NotFound from "./pages/NotFound";
// import Login from "./pages/Login";
// import ProtectedRoute from "./components/ProtectedRoute";
import AppContainer from "./AppContainer";

interface AppProps {
  pathname: string;
  data: any;
}

const App: React.FC<AppProps> = ({ pathname, data }) => {
  return (
    <Routes>
      <Route path="/" element={<AppContainer pathname={pathname} />}>
        <Route index element={<HomePage />} />
        <Route path="about" element={<About />} />
        <Route path="blog" element={<Blog posts={data.posts} />} />
        <Route
          path="blog/:title"
          element={<Post post={data.post} pathname={pathname} />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* 
      You can uncomment and add these routes if needed:
      <Route path="/admin" element={<ProtectedRoute />}>
        <Route path="login" element={<Login />} />
      </Route>
      */}
    </Routes>
  );
};

export default App;

import React from "react";

const About: React.FC = () => {
  return (
    <div className="mx-auto py-10">
      <h1 className="mb-6 text-4xl font-bold">About Our Word Counter</h1>
      <p className="text-gray-700 mb-4">
        Welcome to our website Word Counter—a simple, yet effective tool
        designed to help you count words and characters in your text. Whether
        you are a writer, student, or simply someone who needs to keep track of
        text length, our Word Counter is here to help.
      </p>

      <h2 className="mb-4 text-2xl font-semibold">Why Use a Word Counter?</h2>
      <p className="text-gray-700 mb-4">
        A word counter is quite important for everyone who needs to keep the
        limits of words, monitor writing progress, or check whether the criteria
        of word length satisfaction are met. It will be very important for:
      </p>
      <ul className="mb-4 list-inside list-disc">
        <li className="ml-2">Students who need to write essays and reports</li>
        <li className="ml-2">
          Writers dealing in articles, stories, and books
        </li>
        <li className="ml-2">
          Professionals who write emails, documents, and presentations
        </li>
        <li className="ml-2">
          Content developers dealing with blog posts and social media content
        </li>
      </ul>

      <h2 className="mb-4 text-2xl font-semibold">
        Features of Our Word Counter
      </h2>
      <p className="text-gray-700 mb-4">
        Our Word Counter has a host of features that will help in managing your
        text effectively. Some of these include:
      </p>
      <ul className="mb-4 list-inside list-disc">
        <li className="ml-2">Real-time word and character count</li>
        <li className="ml-2">
          Automatic saving to ensure you don't lose any of your valuable work
        </li>
        <li className="ml-2">Multilingual support</li>
        <li className="ml-2">Super easy-to-use interface</li>
        <li className="ml-2">Exact & reliable count</li>
      </ul>

      <h2 className="mb-4 text-2xl font-semibold">How to Use Word Counter</h2>
      <p className="text-gray-700 mb-4">How our Word Counter is used:</p>
      <ul className="mb-4 list-inside list-disc">
        <li className="ml-2">Type or paste your text in the input area.</li>
        <li className="ml-2">
          Observe how word and character counts update in real-time.
        </li>
      </ul>

      <p className="contact">AutoSave Contact Us</p>
      <p className="text-gray-700 mb-4">
        Should you have any suggestions, questions, or complaints, feel free to
        contact us. We love responses from users and are eager to make this tool
        better with every update to provide the utmost user experience.
      </p>

      <p className="text-gray-700 mb-4">
        Thank you for using our Word Counter. Happy writing!
      </p>
    </div>
  );
};

export default About;

import React, { useState } from "react";
import './Tooltip.scss'

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div
      className="relative inline-block cursor-pointer"
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      {children}

        <div
          className={`tooltip z-50 ${isTooltipVisible ? "showTooltip" : "hideTooltip"} `}
          role="tooltip"
          
          >
          {isTooltipVisible && ( 
            <>
              <div className="tooltip-trie"></div>
              <p className="tooltip-text">{text}</p>
            </>
          )}
        </div>
    </div>
  );
};

export default Tooltip;
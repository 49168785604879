import React, { createContext, useState, ReactNode, FC } from "react";

interface TextContextType {
  fileText: string | null;
  setFileText: (text: string | null) => void;
  fileError: string | null;
  setFileError: (error: string | null) => void;
  isLoading: Boolean | null;
  setIsLoading: (isLoading: boolean) => void;
}

const TextContext = createContext<TextContextType | undefined>(undefined);

export const TextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [fileText, setFileText] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <TextContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fileText,
        setFileText,
        fileError,
        setFileError,
      }}
    >
      {children}
    </TextContext.Provider>
  );
};

export const useText = () => {
  const context = React.useContext(TextContext);
  if (!context) {
    throw new Error("useText must be used within a TextProvider");
  }
  return context;
};

import React from "react";
import { BlogPost } from "../types/types";
import { formattedDate, handleClick } from "../assets/utils";

interface BlogCardProps {
  post: BlogPost;
}

const BlogCard: React.FC<BlogCardProps> = ({ post }) => {
  const { date, pathname, image_src, title, description, _id } = post;

  return (
    <div
      className="mx-auto w-full rounded bg-white"
      onClick={() => handleClick(_id)}
    >
      <a href={pathname} className="flex flex-col md:flex-row">
        <div className="relative min-h-60 w-full md:w-1/3">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={image_src}
            alt={title}
          />
        </div>
        <div className="w-full px-6 py-4 md:w-2/3">
          <div className="mb-2">
            <div className="font-regular lg:font-bold xl:text-base 2xl:text-lg">
              {title}
            </div>
            <p className="text-gray-700 mt-1 text-sm font-light">
              <span className="font-regular">{formattedDate(date)}</span>
            </p>
          </div>
          <p className="font-thin">{description.slice(0, 150) + "..."}</p>
        </div>
      </a>
    </div>
  );
};

export default BlogCard;

import React from "react";
import PostCard from "./PostCard";
import { BlogPost } from "../types/types";

interface PostsProps {
  posts: BlogPost[];
}

const Blog: React.FC<PostsProps> = ({ posts }) => {
  return (
    <div className="bg-gray-100 flex min-h-screen flex-col py-5 lg:pt-10">
      <header className="mb-5">
        <h1 className="text-4xl font-bold lg:text-7xl">Latest Posts</h1>
      </header>
      <main className="flex w-full">
        <div className="grid w-full grid-cols-1 gap-10 lg:grid-cols-2">
          {posts &&
            posts
              .slice()
              .reverse()
              .map((post, i) => (
                <div
                  key={i}
                  className="shadow-lg duration-300 hover:-translate-y-1 hover:shadow-xl"
                >
                  <PostCard post={post} />
                </div>
              ))}
        </div>
      </main>
    </div>
  );
};

export default Blog;

import React, { useRef } from "react";
import Tesseract from "tesseract.js";
import { toast } from "react-toastify";
import { LiaFileUploadSolid } from "react-icons/lia";
import { useText } from "@/assets/hooks/TextProvider";
import { readTextFile } from "@/assets/utils";
import "./FileUploadButton.scss";

interface FileUploadProps {
  device: "desktop" | "mobile";
}

const FileUpload: React.FC<FileUploadProps> = ({ device }) => {
  const { setFileText, setIsLoading } = useText();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const fileType = file.type.match(/document|pdf|text|image/);
    if (!fileType) return;

    try {
      setIsLoading(true);
      if (fileType[0] === "text") {
        const fileText = await readTextFile(file);
        setFileText(fileText);
      } else if (fileType[0] === "image") {
        const {
          data: { text },
        } = await Tesseract.recognize(file, "eng");
        setFileText(text);
      } else if (fileType[0] === "document" || fileType[0] === "pdf") {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", fileType![0]);

        const response = await fetch(`/api/read/file`, {
          method: "POST",
          body: formData,
        });

        const result = await response.text();

        setFileText(result);
      }
    } catch (error) {
      console.error("Error processing file:", error);
      toast.error("An error occurred while processing the file.");
    } finally {
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  return (
    <div className={`fileUpload__wrapper-${device}`}>
      <label htmlFor="file-upload">
        <LiaFileUploadSolid className="icon" />
      </label>
      <input
        id="file-upload"
        type="file"
        ref={fileInputRef}
        accept=".png,.jpg,.jpeg, .txt, .doc, .docx, .pdf"
        onChange={onFileChange}
      />
    </div>
  );
};

export default FileUpload;

import React, { useState, useEffect, useRef } from "react";
import {
  FaFacebookF,
  FaPinterestP,
  FaTwitter,
  FaLink,
  FaEnvelope,
} from "react-icons/fa";
import { Tooltip } from "flowbite-react";

interface ShareButtonsProps {
  pathname: string;
}

const ShareButtons: React.FC<ShareButtonsProps> = ({ pathname }) => {
  const buttonsRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState<Boolean>(false);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      let observer = new IntersectionObserver(
        function (el) {
          if (el[0].target.getBoundingClientRect().top <= 0) {
            setIsFixed(true);
          } else {
            setIsFixed(false);
          }
        },
        { threshold: 1 },
      );

      let target = buttonsRef.current;
      if (target) observer.observe(target);

      return () => {
        if (target) {
          observer.unobserve(target);
        }
      };
    }
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${process.env.BASE_URL}${pathname}`);
  };

  const url = encodeURIComponent(process.env.BASE_URL + pathname);

  return (
    <div ref={buttonsRef} className="relative h-12">
      <div
        className={`top-12 flex ${isFixed ? "fixed -translate-x-10 flex-col" : ""}`}
      >
        <a
          href={`https://www.facebook.com/sharer.php?u=${url}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mb-1 mr-1 rounded-full bg-blue-600 p-2 text-white shadow-md"
        >
          <FaFacebookF />
        </a>
        <a
          href={`https://www.pinterest.com/pin/create/button/?url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mb-1 mr-1 rounded-full bg-red-600 p-2 text-white shadow-md"
        >
          <FaPinterestP />
        </a>
        <Tooltip
          className="tooltip"
          content="Tooltip content"
          trigger="click"
          style="light"
        >
          <button
            onClick={handleCopyLink}
            className="mb-1 mr-1 rounded-full bg-zinc-600 p-2 text-white shadow-md"
          >
            <FaLink />
          </button>
        </Tooltip>
        <a
          href={`https://twitter.com/intent/tweet?url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mb-1 mr-1 rounded-full bg-blue-400 p-2 text-white shadow-md"
        >
          <FaTwitter />
        </a>

        <a
          href={`mailto:?subject=Check out this page&body=${url}`}
          className="mb-1 mr-1 rounded-full bg-yellow-500 p-2 text-white shadow-md"
        >
          <FaEnvelope />
        </a>
      </div>
    </div>
  );
};

export default ShareButtons;

import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Loader: React.FC = () => {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 z-10 m-auto flex h-80 w-80 items-center justify-center">
      <AiOutlineLoading3Quarters className="animate-spin text-9xl text-zinc-700" />
    </div>
  );
};

export default Loader;

import React, { useEffect } from "react";
import ClearButton from "./ClearButton/ClearButton";
import FileUploadButton from "./FileUploadButton/FileUploadButton";

interface TextareaProps {
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  value: string;
  handleTextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  activeTabId: number;
  setTabs: (tabs: any) => void;
}

const Textarea: React.FC<TextareaProps> = ({
  textareaRef,
  value,
  activeTabId,
  setTabs,
  handleTextChange,
}) => {
  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      const startY = e.clientY;
      const startHeight = textareaRef.current
        ? parseInt(window.getComputedStyle(textareaRef.current).height, 10)
        : 0;

      const handleMouseMove = (moveEvent: MouseEvent) => {
        if (textareaRef.current) {
          const newHeight = startHeight + moveEvent.clientY - startY;
          // Ensure the new height is not smaller than the start height
          if (newHeight >= startHeight) {
            textareaRef.current.style.height = `${newHeight}px`;
          }
        }
      };

      const handleMouseUp = () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    };

    const grippie = document.querySelector<HTMLDivElement>(".grippie");
    if (grippie) {
      grippie.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (grippie) {
        grippie.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, []);

  return (
    <>
      <textarea
        autoFocus
        ref={textareaRef}
        className="h-[400px] w-full resize-none border-DEFAULT border-[#ccc] p-[10px] pr-10 font-sans text-xl font-thin tracking-[0.5px] outline-none md:h-[450px] 2xl:pr-[10px]"
        id="text-input"
        placeholder="Type or paste your text here..."
        value={value}
        onChange={handleTextChange}
      ></textarea>

      <>
        <ClearButton
          device="mobile"
          textareaRef={textareaRef}
          setTabs={setTabs}
          activeTabId={activeTabId}
        />
        <div className="absolute bottom-4 right-2 cursor-pointer">
          <FileUploadButton device="mobile" />
        </div>
      </>

      <div className="grippie h-2 w-full cursor-s-resize bg-[#ccc]"></div>
    </>
  );
};

export default Textarea;

import React, { RefObject, useState } from "react";
import { TfiSave } from "react-icons/tfi";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { IoTextOutline } from "react-icons/io5";
import { TbTextSize } from "react-icons/tb";
import Tooltip from "./Tooltip/Tooltip";
import Dropdown from "./Dropdown/Dropdown";
import { RiFontSansSerif } from "react-icons/ri";
import { RiFontSans } from "react-icons/ri";
import { RiFontMono } from "react-icons/ri";
import { RiFontSize2 } from "react-icons/ri";
import FileUploadButton from "./FileUploadButton/FileUploadButton";
import ClearButton from "./ClearButton/ClearButton";

interface SideMenuProps {
  textareaRef: RefObject<HTMLTextAreaElement>;
  activeTabId: number;
  setTabs: any;
  isAutoSaveEnabled: Boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({
  textareaRef,
  activeTabId,
  setTabs,
  isAutoSaveEnabled,
}) => {
  const [currentFontIndex, setCurrentFontIndex] = useState(0);
  const [currentFontSizeIndex, setCurrentFontSizeIndex] = useState(2);
  const [isFontDropdownVisible, setFontDropdownVisible] = useState(false);
  const [isFontSizeDropdownVisible, setFontSizeDropdownVisible] =
    useState(false);

  const fonts: any = [
    { value: "font-sans", icon: <RiFontSans /> },
    { value: "font-serif", icon: <RiFontSansSerif /> },
    { value: "font-mono", icon: <RiFontMono /> },
  ];

  const fontSizes = [
    { value: 16, icon: <RiFontSize2 className="text-xs" /> },
    { value: 18, icon: <RiFontSize2 className="text-sm" /> },
    { value: 20, icon: <RiFontSize2 className="text-base" /> },
    { value: 22, icon: <RiFontSize2 className="text-lg" /> },
    { value: 24, icon: <RiFontSize2 className="text-xl" /> },
  ];

  // const toggleAutoSave = () => {
  //   if (isAutoSaveEnabled) {
  //     localStorage.setItem("tabs", JSON.stringify([initialTab]));
  //     localStorage.setItem("activeTabId", "1");
  //   }
  //   setIsAutoSaveEnabled(!isAutoSaveEnabled);
  // };

  const changeFont = (font: any) => {
    const fontsName = fonts.map((x: { value: any }) => x.value);
    const fontIndex = fontsName.indexOf(font);
    setCurrentFontIndex(fontIndex);
    if (textareaRef.current) {
      textareaRef.current.classList.remove(...fontsName);
      textareaRef.current.classList.add(font);
    }
  };

  const changeFontSize = (fontSize: any) => {
    const fontSizeIndex = fontSizes.map((font) => font.value).indexOf(fontSize);
    setCurrentFontSizeIndex(fontSizeIndex);
    if (textareaRef.current) {
      textareaRef.current.style.fontSize = `${fontSizes[fontSizeIndex].value}px`;
    }
  };

  return (
    <div className="absolute -left-20 hidden flex-col rounded border-1 border-zinc-400 bg-white xxl:flex">
      <Tooltip text={`Auto save is ${isAutoSaveEnabled ? "ON" : "OFF"}`}>
        <div
          // onClick={toggleAutoSave}
          className="cursor-pointer hover:bg-green-200"
        >
          <TfiSave className="border-b-1 border-zinc-400 p-3 text-5xl" />
          {isAutoSaveEnabled ? (
            <FaRegCheckCircle className="absolute bottom-[7px] right-[7px] rounded-full bg-green-400 text-sm text-white" />
          ) : (
            <MdOutlineCancel className="absolute bottom-[7px] right-[7px] rounded-full bg-red-400 text-sm text-white" />
          )}
        </div>
      </Tooltip>
      <Tooltip text="Clear text">
        <div className="cursor-pointer hover:bg-red-200">
          <ClearButton
            device="desktop"
            textareaRef={textareaRef}
            setTabs={setTabs}
            activeTabId={activeTabId}
          />
        </div>
      </Tooltip>
      <Tooltip text="Upload a file">
        <FileUploadButton device="desktop" />
      </Tooltip>
      <div
        className="relative cursor-pointer hover:bg-blue-200"
        onMouseEnter={() => setFontDropdownVisible(true)}
        onMouseLeave={() => setFontDropdownVisible(false)}
      >
        <IoTextOutline className="border-b-1 border-zinc-400 p-3 text-5xl" />
        <Dropdown
          options={fonts}
          onSelect={changeFont}
          selectedValue={fonts[currentFontIndex]}
          isVisible={isFontDropdownVisible}
        />
      </div>
      <div
        className="relative cursor-pointer hover:bg-yellow-200"
        onMouseEnter={() => setFontSizeDropdownVisible(true)}
        onMouseLeave={() => setFontSizeDropdownVisible(false)}
      >
        <TbTextSize className="p-3 text-5xl" />
        <Dropdown
          options={fontSizes}
          onSelect={changeFontSize}
          selectedValue={fontSizes[currentFontSizeIndex]}
          isVisible={isFontSizeDropdownVisible}
        />
      </div>
    </div>
  );
};

export default SideMenu;

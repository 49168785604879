import React from "react";
import './Dropdown.scss'

interface DropdownProps {
  options?: any;
  onSelect?: (value: string | number) => void;
  isVisible: boolean;
  selectedValue?: any;
  text?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ options, onSelect, isVisible, selectedValue, text }) => {
  return (
    <div className={`dropdown ${isVisible ? "showDropdown" : "hideDropdown"}`}>
        {isVisible && (
          <>
            <div className="trie"></div>
            <div className="dropdown__wrapper">
              {onSelect ?
                <div className="dropdown__wrapper-options">
                    {options.map((option: {value: string | number, icon: any}, index: number) => (
                    <div
                        key={index}
                        className={selectedValue.value === option.value ? "bg-zinc-100 !border-extralight-gray" : ''}
                        onClick={() => onSelect(option.value)}
                    >
                        {option.icon}
                    </div>
                    ))}
                </div>
              :
                 <div className="dropdown-text">{text}</div>
              }
            </div>
           </>
        )}
    </div>
  );
};

export default Dropdown;

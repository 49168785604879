import React, { useEffect, useRef } from "react";
import SideMenu from "@/components/SideMenu";
import { useText } from "@/assets/hooks/TextProvider";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Tooltip } from "flowbite-react";
import { IoCloseOutline } from "react-icons/io5";
import SentimentAnalyzer from "@/components/utils/SentimentAnalyser";
import Textarea from "@/components/Textarea";
import Loader from "@/components/Loader";
import { Tab } from "@/types/types";
import SocialsBar from "@/components/SocialsBar";
import useLocalStorageState from "use-local-storage-state";
import "@/components/ClearButton/ClearButton.scss";

const initialTab: Tab = {
  id: 1,
  text: "",
  wordCount: 0,
  charCount: 0,
  sentenceCount: 0,
  paragraphCount: 0,
  charCountNoSpace: 0,
  readingTimeMinutes: 0,
  readingTimeSeconds: 0,
};

const HomePage: React.FC = () => {
  const [tabs, setTabs] = useLocalStorageState<Tab[]>("tabs", {
    defaultValue: [initialTab],
  });

  const [activeTabId, setActiveTabId] = useLocalStorageState<number>(
    "activeTabId",
    { defaultValue: 1 },
  );
  const [isAutoSaveEnabled] = useLocalStorageState<Boolean>(
    "isAutoSaveEnabled",
    { defaultValue: true },
  );

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { fileText, setFileText, isLoading } = useText();

  useEffect(() => {
    if (fileText !== null) {
      setTabs((prevTabs) =>
        prevTabs.map((tab) =>
          tab.id === activeTabId ? { ...tab, text: fileText } : tab,
        ),
      );
      setFileText(null);
    }
  }, [fileText, setFileText, activeTabId]);

  useEffect(() => {
    let storageTab;
    if (tabs.length > 0) {
      storageTab = JSON.parse(localStorage.getItem("tabs")!);
    }

    const activeTab = storageTab.find(
      (tab: { id: number }) => tab.id === activeTabId,
    );
    if (!activeTab) return;

    const trimmedText = activeTab.text.trim();

    // Calculate words and characters
    const wordsArray = trimmedText.match(/\b\p{L}+\b/gu) || [];
    const words = wordsArray.length;
    const characters = activeTab.text.length;
    const charactersNoSpace = activeTab.text.replace(/\s+/g, "").length;

    // Calculate paragraphs
    const paragraphs = trimmedText.split(/\n\s*\n/);
    const nonEmptyParagraphs = paragraphs.filter(
      (paragraph: string) => paragraph.trim() !== "",
    ).length;

    // Calculate sentences
    const sentences = trimmedText.split(
      /(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?)\s/,
    );
    const nonEmptySentences = sentences.filter(
      (sentence: string) => sentence.trim() !== "",
    ).length;

    // Calculate reading time
    const readingTimeInSeconds = Math.ceil(words / (238 / 60));
    const minutes = Math.floor(readingTimeInSeconds / 60);
    const seconds = readingTimeInSeconds % 60;

    const updatedTab = {
      id: activeTab.id,
      text: activeTab.text,
      wordCount: words,
      charCount: characters,
      charCountNoSpace: charactersNoSpace,
      readingTimeMinutes: minutes,
      readingTimeSeconds: seconds,
      paragraphCount: nonEmptyParagraphs,
      sentenceCount: nonEmptySentences,
    };

    setTabs((prevTabs) => {
      return prevTabs.map((tab) => {
        return tab.id === activeTabId ? updatedTab : tab;
      });
    });
    // }, [tabs[activeTabId - 1]?.text, textareaRef?.current?.value]);
  }, [activeTabId, tabs.find((tab) => tab.id === activeTabId)?.text, setTabs]);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) =>
        tab.id === activeTabId ? { ...tab, text: event.target.value } : tab,
      ),
    );
  };

  const handleAddTab = () => {
    if (tabs.length >= Number(process.env.TABS_LIMIT)) return;
    // const newTabId = Math.max(...tabs.map((tab) => tab.id)) + 1;
    const newTabId = tabs.length + 1;
    setTabs([
      ...tabs,
      {
        id: newTabId,
        text: "",
        wordCount: 0,
        charCount: 0,
        sentenceCount: 0,
        paragraphCount: 0,
        charCountNoSpace: 0,
        readingTimeMinutes: 0,
        readingTimeSeconds: 0,
      },
    ]);
    setActiveTabId(newTabId);
  };

  const handleTabClick = (id: number) => {
    setActiveTabId(id);
  };

  const handleCloseTab = (id: number) => {
    if (tabs.length === 1) return;

    setTabs((prevTabs) => {
      const tabIndex = prevTabs.findIndex((tab) => tab.id === id);
      if (tabIndex === -1) return prevTabs; // Tab not found, return unchanged

      // Remove the tab
      const newTabs = prevTabs.filter((tab) => tab.id !== id);

      // Determine new active tab
      let newActiveTabId = activeTabId;
      if (id === activeTabId) {
        // If the closed tab was the active one
        if (newTabs.length > 0) {
          // Set the previous tab as active, or the next one if it was the first tab
          newActiveTabId = newTabs[tabIndex > 0 ? tabIndex - 1 : 0].id;
        }
      }

      // Renumber tabs
      const renumberedTabs = newTabs.map((tab, index) => ({
        ...tab,
        id: index + 1,
      }));

      // Update the active tab ID if it changed due to renumbering
      const updatedActiveTabId =
        renumberedTabs.find((tab) => tab.id === newActiveTabId)?.id ||
        renumberedTabs[0].id;

      // Set new active tab
      setActiveTabId(updatedActiveTabId);

      return renumberedTabs;
    });
  };

  return (
    <div className="w-full pt-5">
      <header>
        {isLoading && <Loader />}
        <div className="relative flex flex-col md:flex-row">
          <SideMenu
            activeTabId={activeTabId}
            setTabs={setTabs}
            textareaRef={textareaRef}
            isAutoSaveEnabled={isAutoSaveEnabled}
          />

          <div className="relative flex w-full max-w-[820px] flex-col">
            <div className="tabs flex">
              {tabs.map((tab) => {
                return (
                  <div
                    key={Math.random()}
                    className={`group relative flex w-[74px] items-center justify-between rounded-t-md border-1 border-b-0 border-zinc-200 bg-zinc-200 px-[6px] text-sm font-extralight duration-100 hover:!bg-slate-900 sm:w-24 sm:px-2 sm:text-base ${tab.id === activeTabId ? "!bg-slate-900" : ""}`}
                  >
                    <button
                      onClick={() => handleTabClick(tab.id)}
                      className={`tab -ml-1 w-full text-left text-zinc-800 duration-100 first-of-type:ml-0 group-hover:!text-slate-200 ${tab.id === activeTabId ? "active !text-zinc-200" : ""}`}
                    >
                      Tab {tab.id}
                    </button>
                    <button
                      onClick={() => handleCloseTab(tab.id)}
                      className={`cursor-pointer text-zinc-700 duration-100 group-hover:!text-slate-200 ${tab.id === activeTabId ? "!text-slate-200" : ""}`}
                    >
                      <IoCloseOutline className="p-0 text-lg" />
                    </button>
                  </div>
                );
              })}
              <button
                onClick={handleAddTab}
                className="add-tab ml-auto rounded-t-md border-1 border-b-0 border-zinc-200 bg-zinc-100 px-2 py-1 text-sm font-extralight duration-100 hover:!bg-slate-900 hover:!text-slate-200 sm:text-base"
              >
                + Add Tab
              </button>
            </div>
            <Textarea
              textareaRef={textareaRef}
              handleTextChange={handleTextChange}
              value={tabs.find((tab) => tab.id === activeTabId)?.text || ""}
              setTabs={setTabs}
              activeTabId={activeTabId}
            />
          </div>
          <div className="counter mt-4 flex min-w-full flex-col border-1 border-light-gray bg-[#f9f9f9] p-3 md:ml-5 md:mt-0 md:min-w-[400px]">
            <div className="flex justify-between bg-extralight-gray p-2">
              {[
                { label: "Words", key: "wordCount" },
                { label: "Characters", key: "charCount" },
                { label: "Sentences", key: "sentenceCount" },
                { label: "Paragraph", key: "paragraphCount" },
              ].map((item) => (
                <div
                  key={item.key}
                  className="flex flex-col items-start text-sm font-normal text-dark-gray sm:text-base sm:font-extralight"
                >
                  <span className="ml-1 text-lg text-black sm:text-2xl">
                    {tabs.find((tab) => tab.id === activeTabId)?.[
                      item.key as keyof Tab
                    ] || 0}
                  </span>
                  <p>{item.label}</p>
                </div>
              ))}
            </div>
            <div className="flex flex-col">
              <div className="flex items-end justify-between border-b-1 border-[#ccc] py-6">
                <p className="text-lg font-light text-dark-gray">
                  Characters
                  <span className="ml-2 text-sm leading-[10px]">
                    (Without white spaces):
                  </span>
                </p>
                <span className="ml-1 text-xl font-extralight text-black">
                  {tabs.find((tab) => tab.id === activeTabId)
                    ?.charCountNoSpace || 0}
                </span>
              </div>
              <div className="flex items-end justify-between border-b-1 border-[#ccc] py-6">
                <div className="flex items-center text-lg font-light text-dark-gray">
                  Reading Time
                  <Tooltip
                    className="left-[866px] top-[88px] w-[212px] break-words text-center text-xs font-light"
                    content="Based on the average speed of 238 words per minute"
                    style="light"
                  >
                    <IoInformationCircleOutline className="ml-1 cursor-pointer" />
                  </Tooltip>
                </div>
                <span className="ml-1 text-xl font-extralight text-black">
                  {tabs.find((tab) => tab.id === activeTabId)
                    ?.readingTimeMinutes || 0}{" "}
                  min{" "}
                  {tabs.find((tab) => tab.id === activeTabId)
                    ?.readingTimeSeconds || 0}{" "}
                  sec
                </span>
              </div>
              <div className="flex flex-col border-b-1 border-[#ccc] pb-5 pt-3">
                <div className="flex items-center py-2 text-lg font-light text-dark-gray">
                  Text Sentiment
                  <Tooltip
                    className="left-[866px] top-[88px] w-[212px] break-words text-center text-xs font-light"
                    content="Analyzing your text sentiment"
                    style="light"
                  >
                    <IoInformationCircleOutline className="ml-1 cursor-pointer" />
                  </Tooltip>
                </div>
                <SentimentAnalyzer
                  text={tabs.find((tab) => tab.id === activeTabId)?.text || ""}
                />
              </div>
            </div>
            <div className="flex items-center pt-5 text-lg font-light text-dark-gray">
              Recommended post length
              <Tooltip
                className="left-[866px] top-[88px] w-[212px] break-words text-center text-xs font-light"
                content="Although some of these platforms allow longer posts, these are the recommended post lengths for each platform."
                style="light"
              >
                <IoInformationCircleOutline className="ml-1 cursor-pointer" />
              </Tooltip>
            </div>
            <SocialsBar
              textLength={
                tabs.find((tab) => tab.id === activeTabId)?.text?.length || 0
              }
            />
          </div>
        </div>
      </header>
      <main className="max-w-[800px]">
        <section className="mb-5">
          <h1 className="my-6 text-2xl font-semibold">
            Word and Character Counter: Count Words and Characters Online
          </h1>
          <p className="text-gray-700">
            A <strong>Word and Character Counter</strong> is an essential online
            tool that helps users accurately count the number of words,
            characters, and other textual elements in a given piece of text.
            This tool is vital for writers, editors, students, and professionals
            who need to adhere to specific word or character limits in their
            work, whether for academic papers, social media posts, or form
            submissions.
          </p>

          <h2 className="mb-4 text-xl font-semibold">
            Key Features and Benefits of the Word and Character Counter
          </h2>
          <ul className="mb-4 list-inside list-disc">
            <li className="ml-2">
              <strong>Instant Counting:</strong> Get real-time updates on word
              and character counts as you type or paste text into the input
              field.
            </li>
            <li className="ml-2">
              <strong>No Space Character Count:</strong> Easily count characters
              excluding spaces, which is useful for applications with strict
              character limits.
            </li>
            <li className="ml-2">
              <strong>User-Friendly Interface:</strong> A simple and intuitive
              design makes this tool accessible to both professionals and
              novices.
            </li>
            <li className="ml-2">
              <strong>Efficiency:</strong> Save time by automatically counting
              words and characters, allowing you to focus on writing and
              editing.
            </li>
            <li className="ml-2">
              <strong>Versatility:</strong> Suitable for various applications,
              including essay writing, composing tweets, and SEO content
              development.
            </li>
          </ul>

          <h2 className="mb-4 text-xl font-semibold">
            How to Use the Word and Character Counter
          </h2>
          <ul className="mb-4 list-inside list-disc">
            <li className="ml-2">
              <strong>Enter Text:</strong> Type or paste your text into the
              textarea provided.
            </li>
            <li className="ml-2">
              <strong>Instant Results:</strong> Instantly view the word count,
              total character count, and characters excluding spaces count.
            </li>
            <li className="ml-2">
              <strong>Adjust Text:</strong> Edit the text directly within the
              tool to meet specific word or character limits.
            </li>
          </ul>

          <h2 className="mb-4 text-xl font-semibold">
            Why Use Our Word and Character Counter?
          </h2>
          <ul className="mb-4 list-inside list-disc">
            <li className="ml-2">
              <strong>Accuracy:</strong> Ensure precise word and character
              counts, crucial for meeting submission requirements.
            </li>
            <li className="ml-2">
              <strong>Productivity:</strong> Streamline your writing process
              with immediate feedback on word and character counts.
            </li>
            <li className="ml-2">
              <strong>Convenience:</strong> Access the tool from any device with
              an internet connection, making it perfect for on-the-go use.
            </li>
            <li className="ml-2">
              <strong>SEO Optimization:</strong> Optimize your content for
              search engines by meeting recommended word lengths, increasing
              visibility and audience engagement.
            </li>
          </ul>

          <p className="text-gray-700">
            The <strong>Word and Character Counter</strong> is a must-have tool
            for anyone involved in writing, from students and writers to digital
            marketers. By simplifying the process of counting words and
            characters, this tool ensures your text meets required criteria with
            ease and precision. Try our Word and Character Counter today to
            enhance your writing workflow and boost your content's
            effectiveness.
          </p>
        </section>
      </main>
    </div>
  );
};

export default HomePage;

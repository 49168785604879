import React from "react";
import { BlogPost } from "../types/types";
import { handleClick } from "../assets/utils";

interface PopularPostsMenuProps {
  posts: BlogPost[];
}

const PopularPostsMenu: React.FC<PopularPostsMenuProps> = ({ posts }) => {
  return (
    <aside className="border-gray-200 fixed top-5 ml-10 hidden h-fit w-1/4 flex-col border bg-white supports-sticky:sticky lg:flex">
      <h2 className="mb-1 bg-black p-2 text-2xl font-semibold text-white">
        POPULAR POSTS
      </h2>
      <ul className="mb-4 px-4">
        {posts.map((post: BlogPost, i: number) => (
          <li
            key={i}
            onClick={() => handleClick(post._id)}
            className="border-gray-300 group mt-3 cursor-pointer border-b text-lg font-extralight last:border-0"
          >
            <a
              className="font-normal group-hover:text-blue-400"
              href={post.pathname}
            >
              <span className="font-semibold">{i + 1}.</span> {post.title}
            </a>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default PopularPostsMenu;

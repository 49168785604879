import React, { useEffect, useState } from "react";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import { TfiInstagram } from "react-icons/tfi";
import { RiTwitterXLine } from "react-icons/ri";
import { Tooltip } from "flowbite-react";

type SocialsBarProps = {
  textLength: number;
};

const SocialsBar: React.FC<SocialsBarProps> = ({ textLength }) => {
  const [barProgress, setBarProgress] = useState(0);

  const MAX_WIDTH = 372;
  const BREAKPOINTS = [0, 70, 80, 125, 150, 180];
  const ICON_POSITIONS = [
    0,
    MAX_WIDTH / 8,
    MAX_WIDTH / 2.7,
    (MAX_WIDTH * 3) / 4.8,
    MAX_WIDTH * 0.87,
    MAX_WIDTH,
  ];

  const calculateProgress = (length: number) => {
    for (let i = 1; i < BREAKPOINTS.length; i++) {
      if (length <= BREAKPOINTS[i]) {
        if (length === BREAKPOINTS[i]) {
          return ICON_POSITIONS[i];
        }
        const segmentProgress =
          (length - BREAKPOINTS[i - 1]) / (BREAKPOINTS[i] - BREAKPOINTS[i - 1]);
        return (
          ICON_POSITIONS[i - 1] +
          segmentProgress * (ICON_POSITIONS[i] - ICON_POSITIONS[i - 1])
        );
      }
    }
    return MAX_WIDTH;
  };

  useEffect(() => {
    const newProgress = calculateProgress(
      Math.min(textLength, BREAKPOINTS[BREAKPOINTS.length - 1]),
    );
    setBarProgress(newProgress);
  }, [textLength]);

  const socials = [
    {
      icon: RiTwitterXLine,
      length: 70,
      content:
        "Posts are limited to 280 characters, but some recommend keeping them up to 70.",
    },
    {
      icon: FaFacebookF,
      length: 80,
      content:
        "Posts can be up to 63,206 characters, but some recommend keeping them up to 80 characters.",
    },
    {
      icon: TfiInstagram,
      length: 125,
      content:
        "Captions are limited to 2,200 characters, but some recommend keeping them up to 120 characters.",
    },
    {
      icon: FaLinkedin,
      length: 150,
      content:
        "Posts are typically around 15n0 characters, which allows readers to see the entire post without being overwhelmed.",
    },
  ];

  return (
    <div className="socials">
      <div className="relative mt-4">
        <div className="relative z-10 mb-2 flex justify-around">
          {socials.map(({ icon: Icon, content, length }) => (
            <Tooltip
              key={length}
              className="w-[212px] break-words text-center text-xs font-light"
              content={content}
              style="light"
            >
              <div className="flex flex-col items-center">
                <div className="rounded-full bg-white shadow-md">
                  <Icon className="h-6 w-6 p-1 text-lg text-black" />
                </div>
                <div className="h-5 w-1 bg-white"></div>
                <div className="text-zinc-500">{length}</div>
              </div>
            </Tooltip>
          ))}
        </div>

        <div className="progress-bar absolute bottom-[30px] h-2 w-full rounded-lg border-1 border-zinc-500 bg-zinc-200">
          <div
            className="h-full rounded-lg bg-black"
            style={{ width: `${barProgress}px` }}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialsBar;

import React, { useState, useEffect } from "react";
import Sentiment from "sentiment";
//mongodb+srv://itzhakshaoulian:Shaoulian415%3F@smartwordcountercluster.3e1nc.mongodb.net/
type SentimentAnalyzerProps = {
  text: string;
};

const SentimentAnalyzer: React.FC<SentimentAnalyzerProps> = ({ text }) => {
  const [positivePercentage, setPositivePercentage] = useState<number>(0);
  const [neutralPercentage, setNeutralPercentage] = useState<number>(0);
  const [negativePercentage, setNegativePercentage] = useState<number>(0);

  const analyzeSentiment = () => {
    const sentiment = new Sentiment();
    const analysisResult = sentiment.analyze(text);

    const positiveScore = analysisResult.positive.length;
    const negativeScore = analysisResult.negative.length;
    const totalWords = text.split(" ").length;
    const neutralScore = totalWords - (positiveScore + negativeScore);

    const positivePercent = (positiveScore / totalWords) * 100;
    const negativePercent = (negativeScore / totalWords) * 100;
    const neutralPercent = (neutralScore / totalWords) * 100;

    setPositivePercentage(positivePercent);
    setNeutralPercentage(neutralPercent);
    setNegativePercentage(negativePercent);
  };

  useEffect(() => {
    analyzeSentiment();
  }, [text]);

  return (
    <div className="flex justify-between text-base font-extralight text-black">
      <p>Positive: {positivePercentage.toFixed(0)}%</p>
      <p>Neutral: {neutralPercentage.toFixed(0)}%</p>
      <p>Negative: {negativePercentage.toFixed(0)}%</p>
    </div>
  );
};

export default SentimentAnalyzer;
